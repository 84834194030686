<template>
  <div class="teacher-detail">
    <zv-nav-bar :title="'名师简介'" :sourceType="sourceType"></zv-nav-bar>
    <div class="part-1">
      <div class="img-box">
        <img :src="item.logoFile" alt="教师图" />
      </div>
      <div class="teacher-name">
        <h5>{{ item.name }}</h5>
        <span v-if="item.position">{{ item.position }} | 教龄{{ item.years }}年</span>
        <span v-else>教龄{{ item.years }}年</span>
      </div>
      <p class="description" v-html="item.description"></p>
    </div>
    <div class="part-2">
      <h5>课程({{ item.courseCount + item.lives.length }})</h5>
      <template v-if="item.courses.length > 0">
        <div class="course-list" v-for="n in item.courses" :key="n.ID" @click="gotoPay(n)">
          <div class="left">
            <div class="left-1">
              <div class="img-box">
                <img :src="n.logoFile" alt="课程图" />
              </div>
              <div class="name">
                <div class="name-view">
                  <span :class="{ 'enterprise-tag': n.enterpriseExclusive === 1 }" style=" white-space: nowrap;">{{ n.liveStatus ? '直播' : n.type !== 0 ? '讲义' : '视频' }}</span>
                  <h5 class="line-ellipsis-2">{{ n.name }}</h5>
                </div>
                <p>{{ n.speaker || '暂无讲师' }}</p>
              </div>
            </div>
            <div class="left-2">
              <!-- <span>概念课</span> -->
              <span>时长{{ n.totalLength || n.liveLength }}</span>
              <div>{{ n.viewCount }}</div>
            </div>
          </div>
          <div class="right">
            <template v-if="!n.isForbid || n.isForbid === '0'">
              <template v-if="n.enterpriseExclusive !== 1">
                <div v-if="n.isFree === '1'" style="font-size: 0.32rem; width: 1.33rem;" @click.stop="gotoStudy(n)">免费</div>
                <template v-else-if="n.hasPermission !== 'Y'">
                  <strong>￥{{ isVip ? n.memberPrice : n.sellPrice }}</strong>
                  <strike v-if="isVip">￥{{ n.sellPrice }}</strike>
                </template>
                <div v-else style="font-size: 0.32rem; width: 1.33rem; color: #1989fa" @click.stop="gotoStudy(n)">进入学习</div>
              </template>
              <template v-else>
                <div v-if="n.enterpriseExclusive !== 1 && n.isFree === '1'" style="font-size: 0.32rem; width: 1.33rem;">免费</div>
                <template v-else-if="n.hasPermission === 'Y'">
                  <div style="font-size: 0.32rem; width: 1.33rem; color: #1989fa">有权限</div>
                </template>
                <div v-else style="font-size: 0.32rem; width: 1.33rem; color: #999">无权限</div>
              </template>
            </template>
            <template v-else>
              <template v-if="n.enterpriseExclusive !== 1">
                <template v-if="n.hasPermission !== 'Y'">
                  <div style="font-size: 0.32rem; width: 1.33rem;">会员专享</div>
                </template>
                <div v-else style="font-size: 0.32rem; width: 1.33rem; color: #1989fa" @click.stop="gotoStudy(n)">进入学习</div>
              </template>
              <template v-else>
                <template v-if="n.hasPermission === 'Y'">
                  <div style="font-size: 0.32rem; width: 1.33rem; color: #1989fa">有权限</div>
                </template>
                <div v-else style="font-size: 0.32rem; width: 1.33rem; color: #999">会员专享</div>
              </template>
            </template>
          </div>
        </div>
      </template>
      <div class="not-data" v-else>
        暂无数据
      </div>
    </div>
    <van-dialog v-model="show" title="提示" show-cancel-button @cancel="show = false" @confirm="gotoPage(`/allCourses/order/${selectItem.ID}?type=LIVE`)">
      <p style="text-align: center;">此直播未购买，是否购买</p>
    </van-dialog>
  </div>
</template>

<script>
import './teacherDetail.less'
export default {
  data() {
    return {
      ID: '',
      item: {
        description: '',
        logoFile: '',
        name: '',
        years: '',
        courseCount: '',
        courses: [],
        position: '',
        lives: [],
      },
      show: false,
      selectItem: {},
      isVip: true,
      sourceType: '',
      isBack: '',
    }
  },
  created() {
    this.ID = this.$route.params.id
    this.getData()
    if (localStorage.userInfo) {
      let info = JSON.parse(localStorage.userInfo)
      this.isVip = info.isVip && (info.enterpriseName || info.enterpriseShorthand)
    }
    this.isBack = this.$route.query.isBack
    // 禁用物理返回键（勿删）
    if (this.isBack === 'Y') {
      this.sourceType = 'teacherDetail'
      history.pushState(null, null, document.URL)
      window.addEventListener('popstate', this.pushHistory, false)
    }
  },
  methods: {
    pushHistory() {
      console.log('禁用按钮的监听')
      if (this.isBack === 'Y') {
        history.pushState(null, null, document.URL)
        this.$Toast.fail('请点击左上角的返回键进行返回')
      }
    },
    // 分享
    setShare() {
      const shareInfo = {
        title: `${this.item.name}`,
        desc: `让教育改变行业，让社会药房更有价值`,
        link: window.location.href,
        img: window.location.href.split('#')[0] + 'shareLogo.jpg',
      }
      // mixins
      this.wechatShare(shareInfo)
    },
    getData() {
      this.$axios
        .get(`/course/front/permit/speaker/${this.ID}`)
        .then((res) => {
          if (res.data.status === 1) {
            this.item = res.data.data
            this.item.courses = this.item.courses.concat(this.item.lives)
            this.setShare()
          } else {
            this.$Toast.fail(res.data.message)
            setTimeout(() => {
              this.goBack()
            }, 3000)
          }
        })
        .catch((e) => {
          this.$Toast.fail('服务器开小差了，稍后重试')
          return e
        })
    },
    gotoPage(path) {
      this.$router.push({ path: path })
    },
    gotoPay(item) {
      let path = `/allCourses/details/${item.ID}/${'curriculum'}`
      if (item.type !== 0) {
        path = `/allCourses/courseHandout/${item.ID}`
      } else {
        path = path + '?backUrl=' + this.$router.history.current.fullPath
        // let temp = this.$router.history.current.fullPath
        // localStorage.back_url = temp
      }
      if (item.liveStatus) {
        path = `/allCourses/listDetails/${item.ID}`
      }
      this.gotoPage(path)
    },
    gotoStudy(item) {
      if (item.liveStatus) {
        this.gotoPage(`/allCourses/listDetails/${item.ID}`)
      } else {
        this.gotoPage(`/allCourses/details/${item.ID}/${'curriculum'}`)
      }
    },
    goBack() {
      if (window.history.length >= 2 && window.location.href.indexOf('isLoged') === -1) {
        if (window.location.href.indexOf('isShare') === -1) {
          this.$router.go(-1)
        } else {
          this.$router.push({
            path: '/',
          })
        }
      } else {
        this.$router.push({
          path: '/',
        })
      }
    },
  },
  destroyed() {
    window.removeEventListener('popstate', this.pushHistory, false)
  },
}
</script>
<style scoped>
.description >>> img {
  max-width: 100%;
}

.name-view {
  display: flex;
  align-items: baseline;
}
.name-view > h5 {
  text-overflow: unset !important;
  white-space: unset !important;
  width: 4.8rem !important;
}
.line-ellipsis-2 {
  display: -webkit-box;
  -webkit-box-orient: vertical;
  -webkit-line-clamp: 2;
  overflow: hidden;
}

.left,
.name {
  flex: 1;
}
.name-view {
  width: 100%;
}
</style>
